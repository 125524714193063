<template>
  <div>
    <v-dialog width="600" v-model="dialogs.import">
      <v-card class="mx-auto">
        <v-card-title>
          Import HTML
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.import = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ImportHtml @input="onImportHtml" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card class="card-title--primary">
      <v-card-title>
        <slot name="title"> Send Email </slot>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="5">
              <div v-if="toList">
                <v-autocomplete
                  outlined
                  label="List Name or ID"
                  :items="emailList"
                  v-model="mailingListId"
                  item-text="title"
                  item-value="id"
                  :filter="onFilter"
                ></v-autocomplete>
                <div v-if="mailingListId">
                  <MailingListEmail
                    background-color="rgba(233, 243, 235, 0.274)"
                    outlined
                    :key="mailingListId"
                    :id="mailingListId"
                    v-model="message.to"
                  >
                    <template #title> Email List </template>
                  </MailingListEmail>
                </div>
              </div>
              <div v-else>
                <v-textarea
                  rows="1"
                  auto-grow
                  outlined
                  label="To"
                  :rules="rules.requiredRule"
                  v-model="message.to"
                ></v-textarea>
              </div>
              <div class="d-flex flex-wrap justify-space-between">
                <v-checkbox label="Send Email to List" class="my-0" v-model="toList"></v-checkbox>
                <v-chip color="primary" v-if="!show.ccBcc" @click="show.ccBcc = true" class="mr-2"
                  >Cc/Bcc</v-chip
                >
              </div>
            </v-col>
            <v-col cols="12" sm="7">
              <v-text-field
                :rules="rules.requiredRule"
                outlined
                label="Subject"
                v-model="message.subject"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-show="show.ccBcc">
              <v-text-field v-model="message.cc" label="Cc" outlined></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-show="show.ccBcc">
              <v-text-field v-model="message.bcc" label="Bcc" outlined></v-text-field>
            </v-col>
            <v-col cols="6" sm="4">
              <v-text-field outlined label="From Name" v-model="message.fromName"></v-text-field>
            </v-col>
            <v-col cols="6" sm="4">
              <v-text-field outlined label="From Email" v-model="message.fromEmail"></v-text-field>
            </v-col>
            <v-col cols="4" class="d-none d-sm-block mt-3 text-h6">
              @{{ message.fromDomain }}
            </v-col>
          </v-row>

          <v-row justify="center" align="center" class="row-mt-1">
            <span>{{ formatEmail(message) }}</span>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea
                auto-grow
                :rules="rules.requiredRule"
                outlined
                label="Message (html)"
                rows="3"
                class="mb-0"
                :disabled="!!message.htmlId"
                v-model="message.html"
              >
              </v-textarea>
              <v-chip @click="removeHtml()" color="primary" v-if="html">
                <v-icon>mdi-language-html5</v-icon>{{ html.title }} <v-icon>mdi-close</v-icon>
              </v-chip>
              <v-btn
                v-if="!message.htmlId"
                outlined
                color="primary"
                @click="dialogs.import = true"
                class="text-capitalize mt-0"
                small
              >
                Import HTML <v-icon>mdi-language-html5</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div class="my-3">
            <p v-html="message.html"></p>
          </div>
          <peeps-alert-animation
            :alertAttrs="alertAttrs"
            ref="peepsAlert"
            :message="alertMessage"
            :color="alertAttrs.color"
          ></peeps-alert-animation>
          <div v-if="retryInterval">
            Retrying...
            <v-btn text @click="clearRetry()">Cancel</v-btn>
          </div>
          <div v-else class="text-center">
            <v-btn :loading="loading" large color="primary" @click="sendEmail" outlined
              >Send
              <v-icon>mdi-email-send</v-icon>
            </v-btn>
            <span @click="cancel()">
              <cancel-button></cancel-button>
            </span>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import rules from '../../mixins/rules';
import config from '../../config';
import ImportHtml from './EmailHtml/Import.vue';
import Email from '../../services/email';
const email = new Email();
const api = config.api();
export default {
  mixins: [rules],
  components: {
    CancelButton: () => import('@/components/Core/CancelButton'),
    PeepsAlertAnimation: () => import('@/components/Core/Alerts/PeepsAlertAnimation.vue'),
    MailingListEmail: () => import('./Mailing/ListEmail.vue'),
    ImportHtml
  },
  props: {
    to: {
      type: String
    },
    subject: {
      type: String
    },
    list: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userId() {
      return this.$store.state.user.uid;
    }
  },
  data() {
    return {
      message: {},
      alertMessage: 'Email sent successfully!',
      alertAttrs: { outlined: true, color: 'secondary' },
      valid: true,
      loading: false,
      MAX_RETRIES: 10,
      RETRY_TIMEOUT: 5000,
      retryCount: 0,
      retryInterval: null,
      toList: false,
      mailingListId: '',
      emailList: [],
      show: {
        ccBcc: false
      },
      dialogs: {
        import: false
      },
      html: null
    };
  },
  watch: {
    list: {
      handler(newValue) {
        this.toList = newValue;
      },
      immediate: true
    },
    toList: {
      handler() {
        this.show.ccBcc = false;
      },
      immediate: true
    }
  },
  created() {
    this.setDefaultData();
    this.getEmailLists();
  },
  methods: {
    removeHtml() {
      this.message.html = '';
      this.message.htmlId = '';
      this.html = null;
    },
    onImportHtml(data) {
      this.message.html = data.html;
      this.message.htmlId = data.id;
      this.html = data;
      this.dialogs.import = false;
    },
    getEmailLists() {
      email.listMailingList({}).then(res => {
        const data = [];
        res.forEach(item => {
          data.push({ ...item.data(), id: item.id });
        });
        this.emailList = data;
      });
    },
    onFilter(item, queryText) {
      return (
        item.id.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
        item.title.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
      );
    },
    setDefaultData() {
      let data = {
        fromDomain: config.emailDomain,
        fromName: 'Peeps Support',
        fromEmail: 'support',
        to: this.to || '',
        subject: this.subject || ''
      };
      this.message = data;
      this.mailingListId = '';
      this.message.to = '';
    },
    formatEmail(data) {
      return `${data.fromName}<${data.fromEmail}@${data.fromDomain}>`;
    },
    showAlert(type = 'success') {
      if (type === 'success') {
        this.alertMessage = 'Email sent successfully!';
        this.alertAttrs = {};
        this.alertAttrs.color = 'success';
      }
      if (type === 'error') {
        this.alertMessage = 'Error! Could not send email';
        this.alertAttrs.color = 'peeps-light-yellow';
        this.alertAttrs.outlined = false;
      }
      this.$refs.peepsAlert.onClick();
      this.$refs.peepsAlert.show();
    },
    checkRetry() {
      let res = true;
      if (this.retryCount >= this.MAX_RETRIES) {
        this.clearRetry();
        res = false;
      }
      return res;
    },
    async retry() {
      let valid = this.checkRetry();
      if (!valid) {
        return;
      }
      await this.sendEmail();
      this.retryCount++;
    },
    setRetryInterval() {
      this.retryInterval = setInterval(() => {
        this.retry();
      }, this.RETRY_TIMEOUT);
    },
    clearRetry() {
      this.retryCount = 0;
      clearInterval(this.retryInterval);
      this.retryInterval = null;
    },
    async send(message) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(`${api.cloudFunction}/adminEmail`, { ...message })
          .then(res => {
            let alertType = 'success';
            this.$emit('sent', message);
            if (res && res.data.status !== 200) {
              alertType = 'error';
            }
            this.showAlert(alertType);
            if (alertType == 'success') {
              this.setDefaultData();
              this.$refs.form.resetValidation();
            }
            if (alertType == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(err => {
            this.showAlert('error');
            console.log(err);
            reject(err);
          });
      });
    },
    async sendEmail() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      const data = {};
      data.from = this.formatEmail(this.message);
      data.html = this.message.html;
      data.subject = this.message.subject;
      if (this.toList) {
        data.to = this.message.to;
        data.type = 'multiple';
      } else {
        data.to = this.message.to.split(',').map(i => i.replace(/\s/g, ''));
      }
      if (this.message.cc) {
        data.cc = this.message.cc.split(',').map(i => i.replace(/\s/g, ''));
      }
      if (this.message.bcc) {
        data.bcc = this.message.bcc.split(',').map(i => i.replace(/\s/g, ''));
      }
      this.loading = true;
      try {
        await this.send(data);
        const logData = {
          user: this.userId,
          ...data,
          toList: this.toList,
          mailingListId: this.mailingListId
        };
        if (this.html && this.html.id) {
          logData.htmlId = this.html.id;
          delete logData.html;
          this.html = null;
        }
        email.createEmailHistory(logData);
        this.clearRetry();
        this.show.ccBcc = false;
        this.setDefaultData();
        this.html = null;
      } catch {
        if (!this.retryInterval) {
          this.setRetryInterval();
        }
        this.showAlert('error');
      }
      this.loading = false;
    },
    cancel() {
      this.setDefaultData();
      this.$emit('cancel');
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<style></style>
