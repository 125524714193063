<template>
  <div v-if="items">
    <v-list>
      <v-list-item v-for="item in items" :key="item.id" @click="onClick(item)">
        <v-list-item-content>
          {{ item.title }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import Email from '../../../services/email';
const email = new Email();
export default {
  created() {
    this.getData();
  },
  data() {
    return {
      items: null
    };
  },
  methods: {
    onClick(item) {
      this.$emit('input', item);
    },
    getData() {
      email.list({ type: email.types.EMAIL_HTML }).then(res => {
        const items = [];
        res.forEach(item => {
          items.push({ ...item.data(), id: item.id });
        });
        this.items = items;
        this.loading = false;
      });
    }
  }
};
</script>

<style>
</style>
